import { Hands } from "@mediapipe/hands/hands";
import { setStorage, getStorage } from "@/utils/storage";
let createDetector, SupportedModels;

if (import.meta.env.MODE !== "development") {
  import("hand-pose-detection-my")
    .then((module) => {
      createDetector = module.createDetector;
      SupportedModels = module.SupportedModels;
    })
    .catch((error) => {
      console.error("Failed to load hand-pose-detection-my:", error);
    });
} else {
  import("@tensorflow-models/hand-pose-detection")
    .then((module) => {
      createDetector = module.createDetector;
      SupportedModels = module.SupportedModels;
    })
    .catch((error) => {
      console.error(
        "Failed to load @tensorflow-models/hand-pose-detection:",
        error
      );
    });
}
class DrawHand {
  constructor(canvas, pointDomArr = []) {
    try {
      const arr = getStorage("handPointArr") || [];
      this.handPointArr = arr;
      this.pointArr = arr;
      this.canvas = canvas;
      this.ctx = canvas.getContext("2d");
      this.pointDomArr = pointDomArr;
    } catch (error) {
      this.pointArr = [];
      this.canvas = canvas;
      this.ctx = canvas?.getContext("2d");
      this.pointDomArr = [];
    }
  }
  authEvent = (pointArr) => {
    if (!pointArr) {
      return true;
    }
    if (
      Math.abs(pointArr[1].y - pointArr[2].y) < 20 &&
      Math.abs(pointArr[2].x - pointArr[1].x) < 20
    ) {
      return true;
    }
    if (
      Math.abs(pointArr[3].y - pointArr[4].y) < 10 &&
      Math.abs(pointArr[3].x - pointArr[4].x) < 10
    ) {
      return true;
    }
    if (
      Math.abs(pointArr[5].y - pointArr[6].y) < 10 &&
      Math.abs(pointArr[5].x - pointArr[6].x) < 10
    ) {
      return true;
    }
    if (
      Math.abs(pointArr[9].y - pointArr[10].y) < 10 &&
      Math.abs(pointArr[9].x - pointArr[10].x) < 10
    ) {
      return true;
    }
    if (
      Math.abs(pointArr[13].y - pointArr[14].y) < 10 &&
      Math.abs(pointArr[13].x - pointArr[14].x) < 10
    ) {
      return true;
    }
    if (
      Math.abs(pointArr[17].y - pointArr[18].y) < 10 &&
      Math.abs(pointArr[17].x - pointArr[18].x) < 10
    ) {
      return true;
    }
    console.log(
      Math.abs(pointArr[1].y - pointArr[2].y) < 20,
      Math.abs(pointArr[2].x - pointArr[1].x) < 20
    );
    return false;
  };
  init = (imgURI) => {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.onload = async () => {
        try {
          let instance = null;
          let o = null;
          try {
            o = window.Hands;
            if (o) {
              console.log("o->>>>", o);
              instance = o;
            } else {
              instance = Hands;
              console.log("Hands->>>", Hands);
            }
            console.log("Hands->>>>", new instance());
          } catch (error) {
            console.log(error);
          }
          const imageBitmap = await createImageBitmap(img);
          const model = SupportedModels.MediaPipeHands;
          const detectorConfig = {
            runtime: "mediapipe",
            solutionPath: "https://cdn.jsdelivr.net/npm/@mediapipe/hands",
            modelType: "full",
          };
          let detector;
          try {
            detector = await createDetector(model, detectorConfig);
            console.log(4396);
          } catch (error) {
            console.log(error, "model->>>");
          }
          const hands1 = await detector.estimateHands(img);
          console.log(hands1, "result->>>>>>>>>>>>>>>>>>>");
          const isHandSupported = (hands) => {
            if (
              !hands ||
              hands.length === 0 ||
              !hands[0].keypoints ||
              hands[0].keypoints.length === 0
            ) {
              return false;
            }

            const hand = hands[0];
            return (
              hand.handedness === "Right" &&
              hand.keypoints[4].x <= hand.keypoints[20].x
            );
          };

          if (!isHandSupported(hands1)) {
            console.log("error,11111");

            return reject("Hand un supports.");
          }
          setStorage("score", hands1[0]?.score);
          let hands;
          try {
            hands = new instance({
              locateFile: (file) =>
                `https://cdn.jsdelivr.net/npm/@mediapipe/hands/${file}`,
            });
            console.log(hands, 77777);
          } catch (error) {
            console.log(error, 7777);
          }
          hands.setOptions({
            maxNumHands: 2,
            modelComplexity: 1,
            minDetectionConfidence: 0.5,
            minTrackingConfidence: 0.5,
          });
          hands.onResults((results) => {
            if (
              results.multiHandLandmarks &&
              results.multiHandLandmarks.length > 0
            ) {
              let handPointArr = results.multiHandLandmarks[0];
              // const originalWidth = this.canvas.width
              // const originalHeight = this.canvas.height
              // const displayWidth = 214
              // const displayHeight = 275

              // const originalRatio = originalWidth / originalHeight
              // const displayRatio = displayWidth / displayHeight
              // const scale =
              //   originalRatio > displayRatio
              //     ? displayWidth / originalWidth
              //     : displayHeight / originalHeight

              // const offsetX = (displayWidth - originalWidth * scale) / 2
              // const offsetY = (displayHeight - originalHeight * scale) / 2
              const scale = 1;
              const offsetX = 0;
              const offsetY = 0;

              for (let i = 0; i < handPointArr.length; i++) {
                handPointArr[i].x =
                  handPointArr[i].x * this.canvas.width * scale + offsetX;
                handPointArr[i].y =
                  handPointArr[i].y * this.canvas.height * scale + offsetY;
              }

              if (this.authEvent(handPointArr)) {
                return reject("hand un supports");
              }
              this.handPointArr = handPointArr;
              this.pointArr = handPointArr;
              setStorage("handPointArr", handPointArr);
              return resolve(handPointArr);
            } else {
              return reject("No hands detected.");
            }
          });
          hands.send({ image: imageBitmap });
        } catch (error) {
          return reject(error.message);
        }
      };
      img.src = imgURI;
    });
  };
  point1AniEvent = () => {
    return new Promise(async (resolve, reject) => {
      let showPointArr = [4, 8, 12, 16, 20];
      for (let i = 0; i < this.handPointArr.length; i++) {
        this.pointDomArr.current[i].style.left =
          this.handPointArr[i].x - 4 + "px";
        this.pointDomArr.current[i].style.top =
          this.handPointArr[i].y - 3 + "px";
        if (showPointArr.indexOf(i) != -1) {
          this.pointDomArr.current[i].style.display = "flex";
          await this.delayEvent();
        }
      }
      return resolve();
    });
  };
  point2AniEvent() {
    return new Promise(async (resolve, reject) => {
      let pointArr = [3, 6, 7, 10, 11, 14, 15, 18, 19];
      for (let i = 0; i < this.pointArr.length; i++) {
        if (pointArr.indexOf(i) != -1) {
          this.pointDomArr.current[i].style.display = "flex";
          await this.delayEvent();
        }
      }
      return resolve();
    });
  }
  point3AniEvent() {
    return new Promise(async (resolve, reject) => {
      let pointArr = [0, 1, 2, 5, 9, 13, 17];
      for (let i = 0; i < this.pointArr.length; i++) {
        if (pointArr.indexOf(i) != -1) {
          this.pointDomArr.current[i].style.display = "flex";
          await this.delayEvent();
        }
      }
      return resolve();
    });
  }
  delayEvent(time = 300) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        return resolve();
      }, time);
    });
  }
  formatDrawDataEvent(startX, startY, endX, endY, total) {
    let currentFrame = 0;
    let pointArr = [];
    while (currentFrame < total) {
      const t = currentFrame / total;
      const currentX = startX + t * (endX - startX);
      const currentY = startY + t * (endY - startY);
      pointArr.push({
        x: currentX,
        y: currentY,
      });
      currentFrame++;
    }
    return pointArr;
  }
  plamLine() {
    return new Promise(async (resolve, reject) => {
      let pointArr = [
        [17, 2],
        [0, 5],
        [0, 13],
        [1, 9],
        [1, 17],
        [2, 17],
      ];
      let pointsArr = [];
      for (let i = 0; i < pointArr.length; i++) {
        let itemArr = this.formatDrawDataEvent(
          this.handPointArr[pointArr[i][0]].x,
          this.handPointArr[pointArr[i][0]].y,
          this.handPointArr[pointArr[i][1]].x,
          this.handPointArr[pointArr[i][1]].y,
          30
        ); // i == 0 ? 45 :
        pointsArr.push(itemArr);
      }
      await this.drawPlamLineAnimation(pointsArr);
      return resolve();
    });
  }
  plamOutLine() {
    return new Promise(async (resolve, reject) => {
      let pointArr = [
        [0, 1],
        [1, 2],
        [2, 5],
        [5, 9],
        [9, 13],
        [13, 17],
        [17, 0],
      ];
      let pointsArr = [];
      for (let i = 0; i < pointArr.length; i++) {
        let itemArr = this.formatDrawDataEvent(
          this.handPointArr[pointArr[i][0]].x,
          this.handPointArr[pointArr[i][0]].y,
          this.handPointArr[pointArr[i][1]].x,
          this.handPointArr[pointArr[i][1]].y,
          30
        ); // i == 0 ? 45 :
        pointsArr.push(itemArr);
      }
      await this.drawPlamLineAnimation(pointsArr);
      return resolve();
    });
  }
  fingerLine() {
    return new Promise(async (resolve, reject) => {
      let pointArr = [
        [
          [4, 3],
          [3, 2],
        ],
        [
          [8, 7],
          [7, 6],
          [6, 5],
        ],
        [
          [12, 11],
          [11, 10],
          [10, 9],
        ],
        [
          [16, 15],
          [15, 14],
          [14, 13],
        ],
        [
          [20, 19],
          [19, 18],
          [18, 17],
        ],
      ];
      let pointsArr = [];
      for (let i = 0; i < pointArr.length; i++) {
        if (!pointsArr[i]) {
          pointsArr[i] = [];
        }
        for (let idx = 0; idx < pointArr[i].length; idx++) {
          let itemArr = this.formatDrawDataEvent(
            this.handPointArr[pointArr[i][idx][0]]?.x,
            this.handPointArr[pointArr[i][idx][0]]?.y,
            this.handPointArr[pointArr[i][idx][1]]?.x,
            this.handPointArr[pointArr[i][idx][1]]?.y,
            30
          ); // i == 0 ? 45 :
          pointsArr[i].push(itemArr);
        }
      }
      await this.drawFingerLineAnimation(pointsArr);
      return resolve();
    });
  }
  drawFingerLineAnimation(pointsArr) {
    return new Promise((resolve, reject) => {
      let currentDrawIndex = 0;
      let currentDrawPIndex = 0;
      let hadDrawArr = [];
      let drawEvent = () => {
        if (currentDrawIndex >= 30) {
          for (let i = 0; i < pointsArr.length; i++) {
            if (pointsArr[i][currentDrawPIndex]) {
              hadDrawArr.push(pointsArr[i][currentDrawPIndex]);
            }
          }
          if (currentDrawPIndex == pointsArr[1].length - 1) {
            return resolve();
          }
          currentDrawPIndex++;
          currentDrawIndex = 0;
        }
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.ctx.shadowBlur = 10;
        this.ctx.shadowOffsetX = 10100;
        this.ctx.shadowOffsetY = 10100;
        this.ctx.shadowColor = "rgba(255,255,255,1)";
        // draw complete
        for (let i = 0; i < hadDrawArr.length; i++) {
          this.ctx.beginPath();
          this.ctx.moveTo(hadDrawArr[i][0].x, hadDrawArr[i][0].y);
          this.ctx.lineTo(
            hadDrawArr[i][hadDrawArr[i].length - 1].x,
            hadDrawArr[i][hadDrawArr[i].length - 1].y
          );
          this.ctx.strokeStyle = "#2cc9fd";
          this.ctx.lineWidth = 2;
          this.ctx.stroke();
          this.ctx.closePath();
        }
        // current draw
        for (let idx = 0; idx < pointsArr.length; idx++) {
          let currentDrawArr = pointsArr[idx][currentDrawPIndex];
          if (currentDrawArr) {
            this.ctx.beginPath();
            this.ctx.moveTo(currentDrawArr[0].x, currentDrawArr[0].y);
            this.ctx.lineTo(
              currentDrawArr[currentDrawIndex].x,
              currentDrawArr[currentDrawIndex].y
            );
            this.ctx.strokeStyle = "#2cc9fd";
            this.ctx.lineWidth = 2;
            this.ctx.stroke();
            this.ctx.closePath();
          }
        }

        currentDrawIndex++;
        requestAnimationFrame(drawEvent);
      };
      drawEvent();
    });
  }
  drawPlamLineAnimation(pointsArr) {
    return new Promise((resolve, reject) => {
      let currentDrawIndex = 0;
      let drawEvent = async () => {
        if (currentDrawIndex >= pointsArr[0].length) {
          return resolve();
        }
        await this.delayEvent(30);
        // current draw
        for (let idx = 0; idx < pointsArr.length; idx++) {
          let currentDrawArr = pointsArr[idx];
          if (currentDrawArr) {
            this.ctx.beginPath();
            this.ctx.moveTo(currentDrawArr[0].x, currentDrawArr[0].y);
            this.ctx.lineTo(
              currentDrawArr[currentDrawIndex].x,
              currentDrawArr[currentDrawIndex].y
            );
            this.ctx.strokeStyle = "#2cc9fd";
            this.ctx.lineWidth = 1;
            this.ctx.stroke();
            this.ctx.closePath();
          }
        }
        currentDrawIndex++;
        requestAnimationFrame(drawEvent);
      };
      drawEvent();
    });
  }
}

export default DrawHand;
