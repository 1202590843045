import React from 'react'
import './Loading.scss'

interface GContainerProps {
  count?: number
  color?: string
  size?: number
}

const GContainer: React.FC<GContainerProps> = ({
  count = 10,
  color = '#01DCFC',
  size = 40,
}) => {
  const styles = {
    '--color': color,
    '--width': `${size / 10}px`,
    '--translate-distance': `${size / 2}px`,
  } as React.CSSProperties

  const boxes = Array.from({ length: count }).map((_, i) => (
    <div
      key={i}
      className="g-box"
      style={{ '--i': i + 1, ...styles } as React.CSSProperties}
    ></div>
  ))

  return (
    <div className="relative">
      <div
        className="g-container g-steps"
        style={{ width: size, height: size }}
      >
        {boxes}
      </div>
    </div>
  )
}

export default GContainer
