import Modal from "@/components/Modal/Modal";
import CustomButton from "@/components/CusButton/CusButton";

type Props = {
  onClose: () => void;
  isOpen: boolean;
};

export default function GiftHintModal({ onClose, isOpen }: Props) {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <CustomButton className="flex relative flex-col justify-around w-[335px] p-5">
          <div>
            <div className="font-bold text-[18px] text-left">
              Mutual Gifts Rules:
            </div>
            <div className="text-[15px] text-[#879EC8] text-left">
              · Each day, you can send a link that allows the first 10 people to
              <span className="text-thc">claim 300-500 $PALM</span> by clicking
              and entering the game. <br />· You can also click on links sent by
              other players to claim their gifts. <br /> · Each player can claim
              up to <span className="text-thc">5 gifts</span> per day from
              others. <br />· Be quick! Only the first{" "}
              <span className="text-thc">10 people</span> can claim each gift
              link daily.
            </div>
          </div>
        </CustomButton>
      </Modal>
    </>
  );
}
