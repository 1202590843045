import { useEffect, useRef, useState } from "react";
import { useNavigateTo } from "@/hooks/useNavigate";
import { useBaseDataStore } from "@/store/baseData";
import { saveFileToLocalStorage } from "@/utils";
import { useTranslation } from "react-i18next";
import "./TakeHandPhoto.css";
import { setStorage,getStorage } from "@/utils/storage";
import handModel from "/imgs/hand/hand-exm.png";
import { CenterPopup, DotLoading, SpinLoading } from "antd-mobile";
import ButtonZg from "@/components/ButtonZg/Buttonzg";
import HandDraw from "./handDraw";
import TakePhoto from "./takePhoto";
import { useTgUserInfo } from "@/hooks/useTguserInfo";
const takePhoto = new TakePhoto();
// const canvasWidth = 214;
// const canvasHeight = 275;

export default function TakeHandPhoto() {
  const { t } = useTranslation();
  const [showTakeScreen, setTakeScreen] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const user = useTgUserInfo();
  const navigateToResult = useNavigateTo("/palm-result");
  const [visible, setVisible] = useState(false);
  const [loadingVisible, setLoadingVisible] = useState(false);
  const updatePalmImg = useBaseDataStore((state) => state.updatePalmImg);
  let canvasDom = useRef(null);
  const pointDomArr = useRef([]);
  const getUrl = async () => {
    try {
      const file = localStorage.getItem("myFile");
      const url = await getUploadUrl(file);
      console.log(url, "itis remote url");
      setFileUrl(url);
    } catch (error) {
      console.log(error);
    }
  };
  const drawCanvasEvent = (imgUrl) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {w,h} = getStorage('videoRectInfo')
        const canvas = canvasDom.current;
        const thumbnailWidth = 214;
        const thumbnailHeight = Math.round(
          (h / w) * thumbnailWidth
        );
        canvas.width = thumbnailWidth;
        canvas.height = thumbnailHeight;
        canvas.style.width = thumbnailWidth + "px";
        canvas.style.height = thumbnailHeight + "px";
        const handDraw = new HandDraw(canvas, pointDomArr);
        await handDraw.init(imgUrl);
        updatePalmImg(imgUrl);
        resolve();
      } catch (error) {
        reject(error, "error!");
      }
    });
  };

  const delayEvent = () => {
    return new Promise(async (resolve, reject) => {
      setTimeout(() => {
        return resolve();
      }, 100);
    });
  };

  const initEvent = async () => {
    setTakeScreen(true);
    await delayEvent();
    takePhoto.initTake();
  };

  const takePhotoEvent = async () => {
    try {
      const {original,thumbnail} = await takePhoto.takePhoto();
      setCapturedImage(original);
      setLoadingVisible(true);
      await drawCanvasEvent(thumbnail);
      setLoadingVisible(false);
      const key = "palm_img" + user.id;
      setStorage(key, thumbnail);
      // let timer = setTimeout(async () => {
      //   try {
      //     clearTimeout(timer);
      //     const response = await fetch(image);
      //     const blob = await response.blob();
      //     const file = new File([blob], "image.jpg", { type: "image/jpeg" });
      //     saveFileToLocalStorage(file);
      //   } catch (error) {
      //     console.log(error, 11111);
      //   }
      // }, 50);
      navigateToResult();
    } catch (error) {
      setVisible(true);
      setLoadingVisible(false);
      setCapturedImage(null);
      console.log(error, "error during takePhotoEvent");
    }
  };
  const handleCloseHint = () => {
    setVisible(false);
    initEvent();
  };
  useEffect(() => {
    initEvent();
  }, []);

  return (
    <>
      <div>
        <div className="my-4 text-thc text-[16px] text-center">
          {t("takePhoto_Invite.wp")}
        </div>
        <div
          className="take_hand_content"
          style={{ display: showTakeScreen ? "block" : "none" }}
        >
          {capturedImage ? (
            <img
              src={capturedImage}
              alt="Captured"
              className="captured_photo video_dom"
            />
          ) : (
            <video
              id="videoDom"
              muted
              className="video_dom"
              playsInline
              autoPlay
            ></video>
          )}
          <div className="model_content">
            {!loadingVisible && (
              <img src={handModel} className="default_hand_icon" alt="" />
            )}
            {loadingVisible && (
              <SpinLoading style={{ "--size": "48px", "--color": "#01DCFC" }} />
            )}
            <div className="take_btn" onClick={takePhotoEvent}>
              {!loadingVisible && <div className="inner_btn"></div>}
              {loadingVisible && <DotLoading />}
            </div>
          </div>
          <div className="w-screen absolute flex items-center justify-center bottom-[155px]">
            <div className="flex items-center justify-center w-[270px] h-[40px] rounded-[20px] bg-black opacity-80">
              <img className="w-[18px] h-[20px]" src="/imgs/page5.png" alt="" />
              <span className="ml-2 text-thc text-[12px]">
                {t("takePhoto_Invite.pf")}
              </span>
            </div>
          </div>
        </div>
      </div>
      <CenterPopup visible={visible} onMaskClick={handleCloseHint}>
        <div className="p-3 text-thc text-[16px] bg-[#030308] ">
          <p className="rounded-[10px]">{t("takePhoto_Invite.take_error")}</p>
          <div className="my-3 flex justify-center" onClick={handleCloseHint}>
            <ButtonZg className="w-[207px] h-[59px]">
              {t("takePhoto_Invite.GotIt")}
            </ButtonZg>
          </div>
        </div>
      </CenterPopup>
      <canvas ref={canvasDom} className="ani_canvas canvas_ani"></canvas>
    </>
  );
}
