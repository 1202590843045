import request from "./config";
import { getAvatar } from "../utils";

type TypeParams = {
  label: number;
  page?: number;
  size?: number;
};

type RankItem = {
  nickname: string;
  score: string;
  rank: number;
  tgid: number;
  reward_ton: number;
};

export type TypeRankList = {
  username: string;
  point: string;
  award: number;
  img: string;
  id: number;
  rank: number;
};

type TypeRankRes = {
  list: TypeRankList[];
  self: TypeRankList;
};

export const transformData = (item: RankItem) => {
  return {
    username: item.nickname,
    point: item.score,
    award: item.reward_ton,
    img: getAvatar(item.tgid),
    id: item.tgid,
    rank: item.rank,
  };
};

const nil = {
  list: [],
  self: {
    username: "",
    point: "",
    award: 0,
    img: "",
    id: 0,
    rank: 0,
  },
};
export const getRankList = async (params: TypeParams): Promise<TypeRankRes> => {
  try {
    const { label, page = 1, size = 100 } = params;
    const type = ["week", "month", "total"][label];
    const res = await request.get("/api/rank", {
      page,
      size,
      type,
    });
    if (res.code === 0) {
      const list = res.data?.ranks.map((item: RankItem) => {
        return transformData(item);
      });
      const selfRank =
        res.data?.self_rank?.month ||
        res.data?.self_rank?.week ||
        res.data?.self_rank?.total;
      return {
        list,
        self: transformData(selfRank),
      };
    }
    return nil;
  } catch (error) {
    console.log(error);
    return nil;
  }
};
