import request from "./config";
import { getTaskStatus } from "../utils";

type TypeTask = {
  id: number;
  target: Record<string, string>;
  reward: Record<string, number>[];
  title: string;
  icon_url: string;
  payload: Record<string, string>;
};

const transformItem = (item: TypeTask) => {
  return {
    id: item.id,
    title: item.title,
    img: item.icon_url,
    status: getTaskStatus(item),
    award: item?.reward ? item?.reward[0]?.number : 0,
    payload: item.payload,
    type: item.target.type,
  };
};
export const getDailyTask = async () => {
  const res = await request.get("/api/tasks/daily-task");
  if (res.code === 0) {
    return res.data?.tasks?.map((item: TypeTask) => {
      return transformItem(item);
    });
  }
  return [];
};

export const getOneTimeTask = async () => {
  const res = await request.get("/api/tasks/one-time");
  if (res.code === 0) {
    return res.data?.tasks?.map((item: TypeTask) => {
      return transformItem(item);
    });
  }
  return [];
};

export const getReward = async (taskId: string | number) => {
  return await request.get(`/api/tasks/${taskId}/get-rewards`);
};

export const getCurrentTaskStatus = async (
  type = 0,
  id = "",
  targetId = ""
) => {
  console.log();
  
  // rember8 | group6 | channel5 | bot7 | app_park12 | story | help
  const type_ = type;
  const urlList: { [key: number]: string } = {
    8: `/tasks-sdk/check/${id}/is-premium?auto_buried=1`,
    6: `/tasks-sdk/check/${id}/group/${targetId}?auto_buried=1`,
    5: `/tasks-sdk/check/${id}/channel/${targetId}?auto_buried=1`,
    7: `/tasks-sdk/check/${id}/bot/${targetId}?auto_buried=1`,
    12: `/tasks-sdk/check/${id}/tapp-park/${targetId}?auto_buried=1`,
    13: `/tasks-sdk/check/${id}/share-story/${targetId}?auto_buried=1`,
    14: `/tasks-sdk/check/${id}/boost-channel?auto_buried=1&jump_url=${targetId}`,
  };
  const api = urlList[type_];
  if (api) {
    const res = await request.get(api);
    if (res.data) {
      return "reward";
    } else {
      return "link";
    }
  }
  return "link";
};

export type RecordType = Record<string, string | number>;
type RecordItem = {
  extra: RecordType | null;
  id: number;
  created_at: string;
  username: string;
  nickname: string;
  gold: number | null;
  tgid: number;
};

type TypeParams = {
  page: number;
  size?: number;
};
export const getInvtiteFriends = async (params: TypeParams) => {
  const { page = 1, size = 20 } = params;
  const res = await request.get(
    `/api/activities/invite-friend?page=${page}&size=${size}`
  );
  if (res.code === 0) {
    return {
      list: res.data.data.map((item: RecordItem) => {
        return {
          username: item.username,
          id: item.id,
          time: item.created_at,
          name: item.nickname,
          gold: item?.gold || 0,
          tgid: item?.tgid,
        };
      }),
      total: {
        all_total: res.data.row_total,
        gold: res.data.gold_total,
      },
    };
  }
  return {
    list: [],
    total: {
      all_total: 0,
      gold: 0,
    },
  };
};
