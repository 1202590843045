import Loading from "@/components/Loading/Loading";
import NotRecordIcon from "/notRecord.svg";
const InfiniteScrollContent = ({
  hasMore,
  text,
  isShow = true,
}: {
  hasMore?: boolean;
  text?: string;
  isShow?: boolean;
}) => {
  return (
    <>
      {hasMore ? (
        <>
          <Loading size={15} />
        </>
      ) : isShow ? (
        <div className="flex flex-col pt-[50px]">
          <img src={NotRecordIcon} className="w-[40px] h-[40px] mb-[20px]" />
          <span className="text-[#879ec8] text-[16px]">
            {text || "not more"}
          </span>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default InfiniteScrollContent;
