import { setStorage } from "@/utils/storage";

class TakePhoto {
  initTake() {
    let videoElement = document.getElementById("videoDom");
    let retryCount = 0;
    const maxRetries = 5;
    const retryDelay = 100;

    async function setupCamera() {
      const videoWidth = videoElement.clientWidth;
      const videoHeight = videoElement.clientHeight;

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            width: videoHeight,
            height: videoWidth,
            facingMode: "environment",
          },
          audio: false,
        });

        videoElement.srcObject = stream;
        return new Promise((resolve) => {
          videoElement.onloadedmetadata = () => {
            resolve(videoElement);
          };
        });
      } catch (error) {
        if (retryCount < maxRetries) {
          retryCount++;
          setTimeout(setupCamera, retryDelay);
        } else {
          // location.reload()
        }
      }
    }

    setupCamera()
      .then((video) => {
        video.play();
      })
      .catch((error) => {
        // location.reload()
      });
  }
  async takePhoto() {
    return new Promise((resolve) => {
      try {
        let videoElement = document.getElementById("videoDom");
        const canvas = document.createElement("canvas");
        let videoWidth = videoElement?.offsetWidth;
        let videoHeight = videoElement?.offsetHeight;
        setStorage("videoRectInfo", {
          w: videoWidth,
          h: videoHeight,
        });

        // 1. 设置原始 Canvas
        canvas.width = videoWidth;
        canvas.height = videoHeight;
        canvas.style.width = videoWidth + "px";
        canvas.style.height = videoHeight + "px";
        const ctx = canvas.getContext("2d");

        // 2. 绘制原始图像
        console.log("videoWidth", videoWidth, videoHeight);
        ctx.drawImage(videoElement, 0, 0, videoWidth, videoHeight);

        // 3. 获取原始图像的 Data URL
        const originalDataUrl = canvas.toDataURL("image/jpeg", 1);

        // 4. 计算缩略图的高度，定宽为 214px
        const thumbnailWidth = 214;
        const thumbnailHeight = Math.round(
          (videoHeight / videoWidth) * thumbnailWidth
        );

        console.log(thumbnailWidth, thumbnailHeight, "thumbnailHeightv->>>>>");

        // 5. 创建新的 Canvas 用于缩略图
        const thumbnailCanvas = document.createElement("canvas");
        const devicePixelRatio = window.devicePixelRatio || 1; // 获取设备像素比
        thumbnailCanvas.width = thumbnailWidth;
        thumbnailCanvas.height = thumbnailHeight;
        const thumbnailCtx = thumbnailCanvas.getContext("2d");
        // thumbnailCtx.scale(devicePixelRatio, devicePixelRatio);
        // 6. 绘制缩略图
        thumbnailCtx.drawImage(
          videoElement,
          0,
          0,
          thumbnailWidth,
          thumbnailHeight
        );

        // 7. 获取缩略图的 Data URL
        const thumbnailDataUrl = thumbnailCanvas.toDataURL("image/jpeg", 1);

        // 8. 返回两个 Data URL
        resolve({
          original: originalDataUrl,
          thumbnail: thumbnailDataUrl,
        });
      } catch (error) {
        console.log(error, 4396);
      }
    });
  }
}

export default TakePhoto;
