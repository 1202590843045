import { FC, ReactNode } from 'react'
import './CusButton.css'

type SciFiButtonProps = {
  children: ReactNode
  onClick?: () => void
  className?: string
  cornerColor?: string
  shadowColor?: string
}

const SciFiButton: FC<SciFiButtonProps> = ({
  children,
  onClick,
  className = '',
  cornerColor = '#01dcfc',
  shadowColor = 'rgba(1, 220, 252, 0.2)',
}) => {
  return (
    <button
      className={`sci-fi-button ${className}`}
      onClick={onClick}
      style={
        {
          '--corner-color': cornerColor,
          '--shadow-color': shadowColor,
        } as React.CSSProperties
      }
    >
      {children}
      <span className="corner top-left"></span>
      <span className="corner top-right"></span>
      <span className="corner bottom-left"></span>
      <span className="corner bottom-right"></span>
    </button>
  )
}

export default SciFiButton
