import Modal from "@/components/Modal/Modal";
import ButtonZg from "@/components/ButtonZg/Buttonzg";
import { useState } from "react";
import { Switch } from "antd-mobile";
import CustomButton from "@/components/CusButton/CusButton";
import LangToggle from "@/components/LangToggle/LangToggle";
import { useTranslation } from "react-i18next";
import Toast from "@/components/Toast/Toast";
type Props = {
  onClose: () => void;
  isOpen: boolean;
};
const supportId = "aedasdasdasdasdasdadasdasdasd";
const duration = 2000;
export default function SettingPopup(props: Props) {
  const { onClose, isOpen } = props;
  const [isShow, setIsShow] = useState(false);
  const [isMusicChecked, setMusicChecked] = useState(true);
  const { t } = useTranslation();
  const [isShowToast, setIsShowToast] = useState(false);

  const handleCopyId = () => {
    setIsShowToast(true);
    const timer = setTimeout(() => {
      clearTimeout(timer);
      setIsShowToast(false);
    }, duration);
    navigator.clipboard.writeText(supportId);
  };

  const handleSettingsClick = () => {
    onClose();
  };
  return (
    <>
      {!isShow ? (
        <Modal isOpen={isOpen} onClose={onClose}>
          <div className="flex flex-col box-border">
            <CustomButton className="flex relative flex-col justify-around w-[335px] h-[220px] px-5">
              <div className="w-full mt-2">
                <div className="flex py-3 border-b-[#283f64] border-b-[0.5px] justify-between">
                  <div> {t("settings.music")}</div>
                  <div>
                    <Switch
                      style={{
                        "--checked-color": "#01DCFC",
                        "--width": "20px",
                        "--height": "20px",
                      }}
                      checked={isMusicChecked}
                      onChange={(checked) => {
                        setMusicChecked(checked);
                      }}
                    />
                  </div>
                </div>
                <div
                  onClick={() => setIsShow(true)}
                  className="flex py-3 border-b-[#283f64] border-b-[0.5px] justify-between"
                >
                  <div>{t("lang")}</div>
                  <div className="flex">
                    <div>{t("language")}</div>
                    <div className="r-45-s"></div>
                  </div>
                </div>
                <div className="flex py-3 border-b-[#283f64] border-b-[0.5px] justify-between">
                  <div>{t("settings.gpp")}</div>
                  <div className="r-45-s"></div>
                </div>
                <div
                  className="flex py-3 flex-col text-thg h-[60px] overflow-y-auto"
                  onClick={handleCopyId}
                >
                  <div>
                    {t("settings.si")} ({t("settings.ptc")})
                  </div>
                  <div className="mt-2">{supportId}</div>
                </div>
              </div>
              <CustomButton className="text-[22px] font-bold flex px-[30px] py-[10px] absolute top-[-25px]">
                {t("settings.settings")}
              </CustomButton>
            </CustomButton>
            <ButtonZg
              className="m-6 w-[170px] h-[50px] text-[22px] text-thc font-bold"
              btnRate={0.24}
              onClick={handleSettingsClick}
            >
              {t("settings.confirm")}
            </ButtonZg>
          </div>
        </Modal>
      ) : (
        <LangToggle
          isOpen={isShow}
          onClose={() => setIsShow(false)}
        ></LangToggle>
      )}
      {isShowToast && (
        <Toast duration={duration} message={t("settings.copied")} />
      )}
    </>
  );
}
