import { TonConnectUI } from "@tonconnect/ui";
import { putUserInfo } from "@/api/user";

class TonConnectService {
  private static instance: TonConnectService | null = null;
  private tonConnectUI: TonConnectUI | null = null;
  private sleepEvent: () => Promise<boolean>;
  private onWalletChangeCallback: ((address: string | null) => void) | null =
    null;

  private constructor() {
    if (!this.tonConnectUI) {
      this.tonConnectUI = new TonConnectUI({
        manifestUrl: "https://webapp.cookiescoin.xyz/tonconnect-manifest.json",
      });
    }

    this.sleepEvent = () => {
      return new Promise((resolve) => {
        const timer = setTimeout(() => {
          clearTimeout(timer);
          return resolve(true);
        }, 500);
      });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.tonConnectUI.onModalStateChange(async (ret: any) => {
      if (ret?.closeReason === "wallet-selected") {
        const address = this.tonConnectUI?.wallet?.account?.address || null;
        console.log("address:", address);
        if (address) {
          putUserInfo(address);
        }
        // status change
        if (this.onWalletChangeCallback) {
          this.onWalletChangeCallback(address);
        }
      }
    });
  }

  public static getInstance(): TonConnectService {
    if (!this.instance) {
      this.instance = new TonConnectService();
    }
    return this.instance;
  }

  public setOnWalletChange(callback: (address: string | null) => void) {
    this.onWalletChangeCallback = callback;
  }

  public async isConnecting(): Promise<boolean> {
    if (!this?.tonConnectUI?.account) {
      await this.sleepEvent();
    }

    if (!this.tonConnectUI) {
      return false;
    }

    return !!this.tonConnectUI.wallet;
  }

  public connect(): Promise<string> {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const payload = Math.random().toString(36).slice(-9);
        if (!this.tonConnectUI) {
          return reject(
            "Please call the init method to initialize the connection first"
          );
        }
        this.tonConnectUI.setConnectRequestParameters({
          state: "ready",
          value: {
            tonProof: payload,
          },
        });
        await this.tonConnectUI.openModal();
        return resolve("modal is open");
      } catch (error) {
        console.log(error);
        return reject("Connect Error");
      }
    });
  }

  public async disconnect(): Promise<boolean | string> {
    if (!this.tonConnectUI) {
      return "Please call the init method to initialize the connection first";
    }

    if (this.tonConnectUI?.wallet) {
      this.tonConnectUI.disconnect();
    }
    return true;
  }
}

export default TonConnectService;
