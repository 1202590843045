import './Toast.scss'

type Props = {
  message: string
  duration?: number
}
export default function Toast(props: Props) {
  const { message, duration = 1500 } = props
  return (
    <div className="toast w-screen h-screen fixed top-0 left-0 z-50 flex justify-center">
      <div
        className="toast-content max-h-[150px] p-4 bg-thc rounded-lg"
        style={{ '--delay': duration + 'ms' } as React.CSSProperties}
      >
        <div className="toast-text">{message}</div>
      </div>
    </div>
  )
}
