import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

interface DropdownProps {
  options: { name: string; value: number }[];
  onSelect: (value: number) => void;
  defaultName: string;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  onSelect,
  defaultName,
}) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(defaultName);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();

  const toggleDropdown = () => setOpen(!open);

  const handleSelect = (option: { name: string; value: number }) => {
    setSelected(option.name);
    onSelect(option.value);
    setOpen(false);
  };

  useEffect(() => {
    setSelected(defaultName);
  }, [defaultName]);
  return (
    <div className="relative" ref={dropdownRef}>
      <div className="flex w-[60px]" onClick={toggleDropdown}>
        <span className="w-[53px] truncate">{t("balance." + selected)}</span>
        <div className="ml-[2px] rtl:mr-[2px] w-0 h-0 border-l-[7px] border-l-transparent border-r-[7px] border-r-transparent border-t-[7px] border-t-thc"></div>
      </div>

      {open && (
        <div className="absolute bg-[#051225] border left-[-60px] rtl:right-[-60px] border-transparent mt-2 z-10 w-[120px]">
          {options.map((option) => (
            <div
              key={option.value}
              className={classNames(
                "p-2 cursor-pointer hover:bg-thc hover:text-black",
                selected !== option.name && "text-thg"
              )}
              onClick={() => handleSelect(option)}
            >
              {t("balance." + option.name)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
