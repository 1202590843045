import { useEffect, useRef, useState, useMemo } from "react";
import { setUserPalmInfo, getUserInfo } from "@/api/user";
import { getStorage } from "@/utils/storage";
import HandDraw from "@/components/TakeHandPhoto/handDraw";
import { getFileFromLocalStorage, loadImg } from "@/utils";
import { getUploadUrl } from "@/api/detection";
import { useBaseDataStore } from "@/store/baseData";
import { useUserDataStore } from "@/store/user";
import { useNavigateTo } from "@/hooks/useNavigate";
import pointIcon from "/imgs/hand/point.png";
import { useTgUserInfo } from "@/hooks/useTguserInfo";
import classNames from "classnames";
import CustomButton from "@/components/CusButton/CusButton";
import Loading from "@/components/Loading/Loading";
import ButtonZg from "@/components/ButtonZg/Buttonzg";
import { useTranslation } from "react-i18next";
import { useBackButton } from "@telegram-apps/sdk-react";
import "./PalmResult.css";
const pointCount = Array.from(Array(21));
const title = ["", "tilte_1", "tilte_1", "title_2"];
const PositioningInfo = [
  "PositioningInfo_1",
  "PositioningInfo_2",
  "PositioningInfo_3",
];
const MeasurementInfo = [
  "MeasurementInfo_1",
  "MeasurementInfo_2",
  "MeasurementInfo_3",
];

const scorecoefficient = getStorage("score") || 0.5;
export default function PalmResult() {
  const canvasDom = useRef(null);
  const pointDomArr = useRef([]);
  const { t } = useTranslation();
  const bb = useBackButton();
  const navigateTo = useNavigateTo("/ranking");
  const navigateToBegin = useNavigateTo("/begin-guide");
  const [handImg, setHandImg] = useState("");
  const [iscurrentStep, setIscurrentStep] = useState(1);
  const user = useTgUserInfo();
  const [decCount, setDecCount] = useState(0);
  const palmResolveImg = useBaseDataStore((state) => state.palmReoslveImg);
  const currentScore = useBaseDataStore((state) => state.currentScore);
  const userinfo = useUserDataStore((state) => state.userinfo);
  const updateBaseinfo = useUserDataStore((state) => state.updateBaseinfo);
  const updatePalmImg = useBaseDataStore((state) => state.updatePalmImg);
  const updateUserCurrentScore = useBaseDataStore(
    (state) => state.updateUserCurrentScore
  );
  const updateUserinfoKey = useUserDataStore(
    (state) => state.updateUserinfoKey
  );
  const [palmCoins, setPalmCoins] = useState(750);
  const navigateToRank = useNavigateTo("/ranking");
  const perentPoint = useMemo(() => {
    return (palmCoins / 1000).toFixed(2) * 100;
  }, [palmCoins]);
  const currentDesc = useMemo(() => {
    if (iscurrentStep === 1) {
      return PositioningInfo;
    }
    return MeasurementInfo;
  }, [iscurrentStep]);
  const { w, h } = getStorage("videoRectInfo") || {
    w: 334,
    h: 385,
  };
  const elW = 214;
  const elH = Math.round((h / w) * elW);
  const canvasWidth = elW;
  const canvasHeight = elH;
  console.log(elW, elH, "elH->>>>>>>>>>>>>>>>");

  const drawCanvasEvent = (imgUrl) => {
    return new Promise(async (resolve, reject) => {
      try {
        const canvas = canvasDom.current;
        if (!canvas) {
          throw new Error("Canvas element is not available.");
        }
        // test
        // setIscurrentStep(3)
        // return resolve()
        // test
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
        canvas.style.width = canvasWidth + "px";
        canvas.style.height = canvasHeight + "px";

        const handDraw = new HandDraw(canvas, pointDomArr);
        // await handDraw.init(imgUrl);
        await handDraw.point1AniEvent();
        setDecCount(1);
        await handDraw.point2AniEvent();
        setDecCount(2);
        await handDraw.point3AniEvent();
        // await handDraw.delayEvent(1000)
        setIscurrentStep(2);
        setDecCount(0);
        await handDraw.fingerLine();
        setDecCount(1);
        await handDraw.plamOutLine();
        setDecCount(2);
        await handDraw.plamLine();
        setDecCount(3);
        setIscurrentStep(3);
        return resolve();
      } catch (error) {
        console.log(error, 4396);
        reject(error);
      }
    });
  };

  const handleCoutinueClick = () => {
    if (userinfo.isBalanceJump) {
      navigateToBegin();
    } else {
      navigateTo();
    }
  };

  useEffect(() => {
    const key = "palm_img" + user.id;
    const img = getStorage(key) || palmResolveImg;
    console.log(img, getStorage(key), "etStorage(key)");
    if (userinfo.isBalanceJump) {
      // setHandImg(handIcon) // test
      if (!img || img === "http://test") {
        navigateToBegin();
      }
      setHandImg(img);
      console.log(userinfo.palmScore);
      setPalmCoins(currentScore);
      setIscurrentStep(3);
    } else {
      if (!img && !userinfo.isBalanceJump) {
        navigateToBegin();
      } else {
        const value = (800 + (50 * 4 * scorecoefficient) / 2) >> 0;
        setPalmCoins(value);
        setHandImg(img);
        getUrl(value);
      }
      drawCanvasEvent(img);
    }
  }, []);
  useEffect(() => {
    if (userinfo.isBalanceJump) {
      bb.show();
    } else {
      bb.hide();
    }
  }, [bb, userinfo.isBalanceJump]);
  bb.on("click", () => {
    bb.hide();
    navigateToRank();
  });

  const getUrl = async (value) => {
    try {
      // const file = await getFileFromLocalStorage();
      // const url = await getUploadUrl(file);
      setUserPalmInfo({
        palmistry_pic: "http://test",
        reward: !userinfo.isBalanceJump ? value : 0,
      }).then(() => {
        getUserInfo().then((res) => {
          if (res.code === 0) {
            const data = res?.data;
            const gold = +data?.gold;
            updateBaseinfo(data);
            updateUserinfoKey({
              point: gold,
            });
            console.log(res, 7777);
            console.log(data?.extra, 111);

            try {
              if (data?.extra?.palmistry_pic) {
                console.log(
                  data?.extra?.palmistry_pic,
                  "data?.extra?.palmistry_pic"
                );
                // updatePalmImg(data?.extra?.palmistry_pic);
                updateUserCurrentScore(data?.extra?.reward || 0);
                loadImg(data?.extra?.palmistry_pic);
              }
            } catch (error) {
              console.log(error);
            }
          } else {
            console.log(res, "res");
          }
        });
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="relative w-screen h-screen flex flex-col align-middle bg-thb box-border">
      <img
        className="w-[335px] flex-shrink-0"
        src="/imgs/Detection_3.png"
        style={{ width: `${335}px`, height: `${elH + 73}px` }}
        alt=""
      />
      <div
        className="canvas-pub"
        style={{ width: `${elW}px`, height: `${elH}px` }}
      >
        <div className="hand_img">
          <img src={handImg} className="hand_img_img" alt="" />
          {pointCount.map((item, index) => (
            <div
              ref={(el) => {
                pointDomArr.current[index] = el;
              }}
              key={index}
              className="point_item"
            >
              <img src={pointIcon} className="point_item_icon" alt="" />
            </div>
          ))}
          <canvas ref={canvasDom} className="ani_canvas canvas_ani"></canvas>
          {iscurrentStep !== 3 && <div className="ani-blue"></div>}
        </div>
      </div>
      <div className="text-[24px] text-thc text-center mt-2 font-bold">
        {t("palmResultTitle." + title[iscurrentStep])}
      </div>
      {iscurrentStep !== 3 && (
        <div className="flex justify-around mt-4 w-screen text-[18px] text-[#D1DCF1] ">
          <div
            className={classNames(
              iscurrentStep === 1 ? "active-tab" : "deactive-tab"
            )}
          >
            {t("palmResultTitle.Positioning")}
          </div>
          <div
            className={classNames(
              iscurrentStep === 2 ? "active-tab" : "deactive-tab"
            )}
          >
            {t("palmResultTitle.Measurement")}
          </div>
        </div>
      )}
      <div className="mt-3 flex flex-col justify-center">
        {iscurrentStep !== 3 &&
          currentDesc.map((item, index) => {
            return (
              <CustomButton key={index} className="mt-2 w-[336px] h-[65px]">
                <div className="flex justify-between text-left">
                  <div className="text-[17px] w-[205px]">
                    {t("palmResultTitle." + item)}
                  </div>
                  {decCount > index ? (
                    <img className="w-5 h-5" src="/imgs/completed.png" />
                  ) : (
                    <Loading size={20}></Loading>
                  )}
                </div>
              </CustomButton>
            );
          })}

        {iscurrentStep === 3 && (
          <div className="flex flex-col justify-center pb-3">
            <CustomButton className="w-[336px] text-[15px]">
              {t("palmResultTitle." + "desContent")}
            </CustomButton>
            <div className="flex justify-center mt-2 w-[317px] h-[84px] result-wrapper">
              <img className="w-[62px] h-[62px]" src="/imgs/earn.png" alt="" />
              <div className="ml-6 text-[75px] text-thc">{palmCoins}</div>
            </div>
            <CustomButton className="mt-2 w-[336px] text-[15px]">
              {t("palmResultTitle." + "result")}
              <span className="px-1 text-[22px]">{perentPoint}%</span>
              {t("palmResultTitle." + "result_hint")}
            </CustomButton>
            <div className="mt-2" onClick={handleCoutinueClick}>
              <ButtonZg className="w-[207px] h-[59px] text-[24px] text-thc">
                {t("Continue")}
              </ButtonZg>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
