import CustomButton from "@/components/CusButton/CusButton";
import Dropdown from "@/components/Dropdown/Dropdown";
import ButtonZg from "@/components/ButtonZg/Buttonzg";
import { useUserDataStore } from "@/store/user";
import { InfiniteScroll } from "antd-mobile";
import { getWalletRecord, type RecordType } from "@/api/record";
import { useNavigateTo } from "@/hooks/useNavigate";
import { useEffect, useState } from "react";
import InfiniteScrollContent from "@/components/InfiniteScrollContent/InfiniteScrollContent";
import { useTranslation } from "react-i18next";
import TonConnectService from "@/config/pay";
import "./index.scss";

const typeList = [
  {
    name: "All",
    value: 0,
  },
  {
    name: "Perks",
    value: 1,
  },
  {
    name: "Invite",
    value: 2,
  },
  {
    name: "Task",
    value: 3,
  },
  {
    name: "reward",
    value: 4,
  },
];
const tonConnectService = TonConnectService.getInstance();

export default function Banlance() {
  const userinfo = useUserDataStore((state) => state.userinfo);
  const updateUserinfoKey = useUserDataStore(
    (state) => state.updateUserinfoKey
  );
  const navigateToBegin = useNavigateTo("/palm-result");
  const [currentType, setCurrentType] = useState(0);
  const [balanceList, setBalanceList] = useState<RecordType[]>([]);
  const [isBindWallet, setIsBindWallet] = useState(false);
  const { t } = useTranslation();
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  async function loadMore(page = 1, activeKey = 0) {
    console.log(page, "more->>>>>>");
    const append = await getWalletRecord({
      type: activeKey,
      page,
    });
    setBalanceList((val) => {
      if (page === 1) {
        return append;
      }
      return [...val, ...append];
    });
    setHasMore(append.length > 0);
    setPage((prevPage) => prevPage + 1);
  }
  const getWallet = async () => {
    const isConnected = await tonConnectService.isConnecting();
    setIsBindWallet(isConnected);
  };
  const handleChangeType = (activeKey: number) => {
    console.log(7777, activeKey);
    setCurrentType(activeKey);
    setPage(1);
    setHasMore(true);
    loadMore(1, activeKey);
  };
  const handleDisWalletClick = async () => {
    await tonConnectService.disconnect();
    const timer = setTimeout(() => {
      clearTimeout(timer);
      getWallet();
    }, 200);
  };

  const handleConnectWalletClick = async () => {
    if (!isBindWallet) {
      try {
        await tonConnectService.connect();
      } catch (error) {
        console.error(error);
      }
    }
  };
  useEffect(() => {
    tonConnectService.setOnWalletChange((address: string | null) => {
      console.log(address);
      setIsBindWallet(true);
    });
    getWallet();
  }, []);
  return (
    <div className="w-full flex flex-col text-thc balance">
      <CustomButton className="w-[336px] mt-[20px]">
        <div
          className="flex justify-between w-full"
          onClick={() => handleConnectWalletClick()}
        >
          <img className="w-[24px] h-[24px]" src="/imgs/balance_1.png" alt="" />
          <div className="text-[18px] font-bold">
            {!isBindWallet
              ? t("balance.Connect_wallet")
              : t("balance.disconnect_wallet")}
          </div>
          {isBindWallet ? (
            <img
              onClick={() => handleDisWalletClick()}
              className="w-[24px] h-[24px]"
              src="/imgs/balance_2.png"
              alt=""
            />
          ) : (
            <div className="w-[24px] h-[24px]"></div>
          )}
        </div>
      </CustomButton>
      <div className="flex">
        <img className="w-[62px] h-[62px]" src="/imgs/earn.png" alt="" />
        <div className="text-[60px] font-bold ml-[14px] rtl:mr-[14px]">
          {userinfo.point}
        </div>
      </div>
      <img className="w-[130px] h-[130px]" src="/imgs/balance_3.png" alt="" />
      <div>
        <ButtonZg
          onClick={() => {
            updateUserinfoKey({
              isBalanceJump: true,
            });
            navigateToBegin();
          }}
          isLine={false}
          className="mt-[8px] w-[231px] h-[53px] text-[18px] font-bold"
        >
          {t("balance.Palm_Fortune_telling")}
        </ButtonZg>
      </div>
      <div className="mt-[20px] text-[18px] flex justify-between w-[335px] p-[12px] rounded-lg bg-[#091c38]">
        <div> {t("balance.Income_History")}</div>
        <Dropdown
          options={typeList}
          onSelect={handleChangeType}
          defaultName="All"
        />
      </div>
      <div className="flex flex-col w-full mt-[20px] px-[20px] gap-4">
        {balanceList.map((item, index) => {
          return (
            <div key={index} className="flex w-full justify-between">
              <div className="flex">
                <img
                  className="w-[46px] h-[46px]"
                  src={`/imgs/banlance/blance_${item.type}.png`}
                  alt=""
                />
                <div className="flex flex-col">
                  <div className="ml-[20px] rtl:mr-[20px] truncate w-[140px] text-[18px] font-bold">
                    {item.desc}
                  </div>
                  <div className="w-[140px] pl-[10px] rtl:pr-[10px] text-thg text-[14px]">
                    {item.time}
                  </div>
                </div>
              </div>
              <div className="font-bold text-thc text-[18px] flex">
                <span>+{item.score}</span>
                <img
                  className="w-[27px] h-[27px] ml-[10px]"
                  src="/imgs/earn.png"
                  alt=""
                />
              </div>
            </div>
          );
        })}
      </div>
      <InfiniteScroll
        loadMore={() => loadMore(page, currentType)}
        hasMore={hasMore}
      >
        <InfiniteScrollContent isShow={!balanceList.length} hasMore={hasMore} />
      </InfiniteScroll>
    </div>
  );
}
