import { type FC, ReactNode, CSSProperties } from "react";
import classNames from "classnames";
import "./index.scss";

interface BtnComponentsProps {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
  style?: CSSProperties;
  isLine?: boolean;
  btnRate?: number;
  topValue?: string;
}

const BtnComponents: FC<BtnComponentsProps> = ({
  children,
  className,
  onClick,
  style,
  btnRate = 0.29,
  topValue = "10%",
}) => {
  const domArr = Array.from(Array(4));
  return (
    <>
      <div
        style={style}
        className={classNames("btn", className)}
        onClick={onClick}
      >
        {domArr.map((_, index) => (
          <div
            key={index}
            className={classNames(
              "top_line_content_item",
              index === 0 && "left_top_line_content",
              index === 1 && "right_top_line_content",
              index === 2 && "left_bottom_line_content",
              index === 3 && "right_bottom_line_content"
            )}
          >
            <div
              className="left_top_border"
              style={{
                top: 0,
                left: 0,
                height: 50 * btnRate + "px",
              }}
            >
              <div
                className="left_top_border_line"
                style={{
                  top: topValue,
                  left: 50 * btnRate * 0.5 + "px",
                  width: "calc(100% - " + (53 * btnRate * 0.5 - 0.5) + "px)",
                  height: 50 * btnRate + "px",
                  borderTop: 2 * btnRate + "px solid #01dcfc",
                }}
              ></div>
            </div>

            <div
              className="left_left_border"
              style={{
                top: 35 * btnRate + "px",
                height: "calc(50% - 1px - 30px * " + btnRate + " )",
                borderLeft: 2 * btnRate + "px solid #01dcfc",
              }}
            ></div>
            <div
              className="left_line left_top_line"
              style={{ transform: "scale(" + btnRate + ")" }}
            >
              <div className="left_1"></div>
              <div className="left_2"></div>
              <div className="left_3"></div>
              <div className="left_4"></div>
              <div className="left_5"></div>
              <div className="left_6"></div>
            </div>
          </div>
        ))}
        <div className="center_content">
          <span>{}</span>
          {children}
        </div>
      </div>
    </>
  );
};

export default BtnComponents;
