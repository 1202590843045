import React, { useEffect } from 'react'

interface NumberAnimationProps {
  number: number
  duration?: number
  onComplete?: () => void
}

const NumberAnimation: React.FC<NumberAnimationProps> = ({
  number,
  duration = 1000,
  onComplete,
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (onComplete) {
        onComplete()
      }
    }, duration)

    return () => clearTimeout(timer)
  }, [duration, onComplete])

  return (
    <div className="fixed w-full h-screen flex justify-center items-center">
      <div className="text-2xl opacity-100 animate-flyUp font-bold text-thc">
        +{number}
      </div>
    </div>
  )
}

export default NumberAnimation
