import { type TypeRankList } from "@/api/rank";

type Props = {
  item: TypeRankList;
};
const RankingItem = (props: Props) => {
  const { item } = props;
  return (
    <div className="flex h-[50px] border-b border-[#15263d] justify-between align-middle mt-2 text-thc bo">
      <div className="flex">
        <img
          className="w-[47px] h-[47px] rounded-md mr-1 default-img"
          src={item.img as string}
          alt=""
        />
        <div className="ml-2 rtl:mr-2">
          <div className="flex align-middle">
            <div className="max-w-[155px] truncate font-bold">
              {item.username}
            </div>
            {item.award && (
              <>
                {" "}
                <img
                  className="w-[13px] h-[13px] mx-1"
                  src="/imgs/ton.png"
                  alt=""
                />
                <span>{item.award}</span>
              </>
            )}
          </div>
          <div className="mt-2 flex align-middle justify-start">
            <img
              className="w-[15px] h-[15px] mr-1 rtl:ml-1"
              src="/imgs/earn.png"
              alt=""
            />
            <span className="text-[#c7d9f9]">{item.point}</span>
          </div>
        </div>
      </div>
      <div className="text-thc text-[20px]">{item.rank}</div>
    </div>
  );
};

export default RankingItem;
