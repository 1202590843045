import { useTranslation } from 'react-i18next'

export default function SystemMaintenance() {
  const { t } = useTranslation()
  return (
    <div className="w-screen h-screen bg-thb flex flex-col justify-center px-[20px] text-thc">
      <img className="w-[183px] h-[183px]" src="/imgs/maintenance.png" alt="" />
      <div className="mt-[52px] text-[30px] font-bold">
        {t('settings.systemTitle')}
      </div>
      <div className="text-center mt-[24px]">{t('settings.systemDes')}</div>
    </div>
  )
}
