import i18n from "@locales/i18next";
import Modal from "../Modal/Modal";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import ButtonZg from "@/components/ButtonZg/Buttonzg";
import CustomButton from "@/components/CusButton/CusButton";

type Props = {
  onClose: () => void;
  isOpen: boolean;
};

const languages = [
  { id: 1, lang: "en", text: "English" },
  { id: 2, lang: "ru", text: "Русский" },
  { id: 3, lang: "pt", text: "Português" },
  { id: 4, lang: "es", text: "Español" },
  { id: 5, lang: "de", text: "Deutsch" },
  { id: 6, lang: "fr", text: "Français" },
  { id: 7, lang: "tr", text: "Türkçe" },
  { id: 8, lang: "ar", text: "العربية" },
  { id: 9, lang: "hi", text: "हिन्दी" },
  { id: 10, lang: "uk", text: "Українська" },
];
export default function LangToggle(props: Props) {
  const { onClose, isOpen } = props;
  const currentLanguage = localStorage.getItem("i18nextLng_code") || "en";
  const [isOptioned, setIsOptioned] = useState(currentLanguage);
  console.log(i18n.language, 7777);
  const { t } = useTranslation();
  const changeLanguage = (value: string) => {
    setIsOptioned(value);
  };

  const handleClick = () => {
    i18n.changeLanguage(isOptioned);
    document.body.dir = i18n.dir();
    localStorage.setItem("i18nextLng_code", isOptioned);
    onClose();
  };
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <div className="flex flex-col box-border">
          <CustomButton className="flex relative flex-col justify-around w-[335px] h-[220px] px-5">
            <div className="w-full h-[200px] mt-3 overflow-y-auto">
              {languages.map((item) => {
                return (
                  <div
                    key={item.id}
                    className={`${
                      item.lang === isOptioned ? "text-thc" : "text-thg"
                    } flex py-3 border-b-[#283f64] border-b-[0.5px] justify-between`}
                    onClick={() => changeLanguage(item.lang)}
                  >
                    <span>{item.text}</span>
                    {item.lang === isOptioned ? (
                      <img
                        className="w-[20px] h-[20px]"
                        src="/imgs/completed.png"
                        alt=""
                      />
                    ) : (
                      <div className="w-[20px] h-[20px]"></div>
                    )}
                  </div>
                );
              })}
            </div>
            <CustomButton className="text-[22px] font-bold flex px-[30px] py-[10px] absolute top-[-25px]">
              {t("language")}
            </CustomButton>
          </CustomButton>
          <ButtonZg
            className="m-6 w-[170px] h-[50px] text-[22px] text-thc font-bold"
            btnRate={0.24}
            onClick={handleClick}
          >
            {t("settings.confirm")}
          </ButtonZg>
        </div>
      </Modal>
    </>
  );
}
