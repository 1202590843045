import { useUserDataStore } from "@/store/user";
import { useBaseDataStore } from "@/store/baseData";
// import { SafeArea } from "antd-mobile";
import { useNavigateTo } from "@/hooks/useNavigate";
import { getRankList, type TypeRankList } from "@/api/rank";
import { getDailyRewardInfo } from "@/api/user";
import { getUserInfo } from "@/api/user";
import { useMemo, useEffect, useState, useCallback } from "react";
import { InfiniteScroll } from "antd-mobile";
import InfiniteScrollContent from "@/components/InfiniteScrollContent/InfiniteScrollContent";
import RankingItem from "./RankItem";
import DailyPopup from "@/components/DailyPopup/DailyPopup";
import Loading from "@/components/Loading/Loading";
import NumberAnimation from "@/components/NumberAnimation/NumberAnimation";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import SettingPopup from "./SettingPopup";
import "./Ranking.css";

const tabList = [
  {
    name: "Weekly",
    id: 0,
  },
  {
    name: "Monthly",
    id: 1,
  },
  {
    name: "All_Time",
    id: 2,
  },
];
export default function Ranking() {
  const navigateToHome = useNavigateTo("/begin-guide");
  const palmReoslveImg = useBaseDataStore((state) => state.palmReoslveImg);
  const userinfo = useUserDataStore((state) => state.userinfo);
  const updateUserinfoKey = useUserDataStore(
    (state) => state.updateUserinfoKey
  );
  const updateBaseinfo = useUserDataStore((state) => state.updateBaseinfo);
  const [isLoading, setLoading] = useState(true);
  const [current, setCurrent] = useState(1);
  const [RankList, setRankList] = useState<Array<TypeRankList>>([]);
  const [selfRank, setSelfRank] = useState({
    username: "",
    point: "",
    award: 0,
    img: "",
    id: 0,
    rank: 0,
  });
  const [ntShow, setNtshow] = useState(false);
  const [isSettingShow, setIsSettingShow] = useState(false);
  const [award, setAward] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const topList = useMemo(() => {
    if (RankList.length) {
      if (RankList.length < 3) {
        return RankList;
      }
      return [RankList[1], RankList[0], RankList[2]];
    }
    return [];
  }, [RankList]);
  useEffect(() => {
    if (!palmReoslveImg) {
      console.log(palmReoslveImg, "palmReoslveImg");
      navigateToHome();
    }
  }, []);
  async function loadMore(page = 1, activeKey = 0) {
    const { list, self } = await getRankList({
      label: activeKey,
      page,
    });
    setLoading(false);
    setRankList((val) => {
      if (page === 1) {
        return list;
      }
      return [...val, ...list];
    });
    setHasMore(list.length > 0);
    setPage((prevPage) => prevPage + 1);
    console.log(self, 17777);

    setSelfRank(self);
    // setMineInfo(self)
  }

  const getInfo = useCallback(() => {
    getUserInfo().then((res) => {
      if (res.code === 0) {
        const data = res?.data;
        const gold = +data?.gold;
        if (userinfo.point !== gold) {
          updateBaseinfo(data);
          updateUserinfoKey({
            point: gold,
          });
        }
      }
    });
  }, [updateBaseinfo, updateUserinfoKey, userinfo.point]);
  const handleClose = (award: number) => {
    setAward(award);
    setNtshow(true);
    console.log(award, 777);
    const value = (userinfo.point as number) + award;
    updateUserinfoKey({
      point: value,
    });
    getInfo();
    loadMore(1);
    const tiemr = setTimeout(() => {
      clearTimeout(tiemr);
      setNtshow(false);
    }, 1000);
  };
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     getInfo();
  //   }, 20000);
  //   return () => clearInterval(intervalId);
  // }, [getInfo]);
  useEffect(() => {
    const fn = async () => {
      const res = await getDailyRewardInfo();
      updateUserinfoKey({
        isDailyFristLogin: !res.today_reward_is_get,
        currentAwardDay: res.consecutive_days,
        currentLoginReward: res.reward,
      });
    };
    fn();
  }, [updateUserinfoKey]);
  return (
    <div className="flex flex-col align-middle leading-tight bg-thb box-border">
      <div className="mt-1 w-screen flex justify-center relative align-middle">
        <img className="w-[32px] h-[32px] mr-3" src="/imgs/earn.png" alt="" />
        <div className="text-[40px] text-thc font-bold">{userinfo.point}</div>
        <img
          className="w-[32px] h-[32px] absolute rtl:left-4 right-4 rtl:right-[unset] top-2"
          src="/imgs/settings.png"
          alt=""
          onClick={() => setIsSettingShow(true)}
        />
      </div>
      <div className="mt-1 text-center text-[24px] text-thg">
        #{selfRank.rank}
      </div>
      {/* top3 */}
      <div className="flex justify-center mt-8">
        {!isLoading ? (
          topList.length ? (
            topList.map((item, i) => {
              const isMiddle = i === 1;
              return (
                <div
                  className={classNames(
                    "flex flex-col align-middle justify-around text-thc rank-top mr-[6px] transition-transform w-[86px] h-[131px] relative",
                    isMiddle && "w-[101px] h-[149px]"
                  )}
                  style={{
                    transform: isMiddle ? "translateY(-20px)" : "translateY(0)",
                  }}
                  key={i}
                >
                  <img
                    className={classNames(
                      "absolute top-0 left-[3px] w-[18px] h-[21px]",
                      isMiddle && "w-[21px] h-[25px]"
                    )}
                    src={`/imgs/rank/rank_${item?.rank || 1}.png`}
                    alt=""
                  />
                  <img
                    className={classNames(
                      "w-[47px] h-[47px] border-2 border-[#AEC9D8] rounded-full mr-1 default-img",
                      isMiddle && "border-[#d6bc24]",
                      i === 2 && "border-[#d4762f]"
                    )}
                    src={item.img}
                    alt=""
                  />
                  <div className="w-[85px] text-[12px] font-bold text-center truncate">
                    {item.username}
                  </div>
                  <div className="flex pl-1">
                    <img
                      className="w-[15px] h-[15px] mr-1 rtl:ml-1 text-thg"
                      src="/imgs/earn.png"
                      alt=""
                    />
                    <span className="truncate max-w-[80px] text-[#c7d9f9]">
                      {item.point}
                    </span>
                  </div>
                  {item.award ? (
                    <div className="flex pl-1">
                      <img
                        className="w-[15px] h-[15px] mr-1"
                        src="/imgs/ton.png"
                        alt=""
                      />
                      <span className="truncate max-w-[80px]">
                        {item.award}
                      </span>
                    </div>
                  ) : (
                    <div className="flex pl-1">
                      <div className="w-[15px] h-[15px] mr-1" />
                      <span className="truncate max-w-[80px]"></span>
                    </div>
                  )}
                </div>
              );
            })
          ) : (
            <div className="flex h-[131px]"></div>
          )
        ) : (
          <div className="flex h-[131px]">
            <Loading size={20}></Loading>
          </div>
        )}
      </div>
      {/* tabs */}
      <div className="flex justify-around mt-6 mx-4 w-[305px] text-[18px] text-[#D1DCF1]">
        {tabList.map((item, index) => {
          return (
            <div
              key={index}
              className={classNames(
                current === item.id ? "active-tab" : "deactive-tab",
                "pb-2 px-2 truncate"
              )}
              onClick={() => {
                setCurrent(item.id);
                setPage(1);
                setHasMore(true);
                loadMore(1, item.id);
              }}
            >
              {t("rank." + item.name)}
            </div>
          );
        })}
      </div>
      {/* others */}
      <div className="w-screen px-5 pb-[60px]">
        {!isLoading ? (
          RankList.slice(3).map((item, index) => (
            <RankingItem key={index} item={item}></RankingItem>
          ))
        ) : (
          <div className="flex justify-center h-[40vh]">
            <Loading size={20}></Loading>
          </div>
        )}
      </div>
      {/* mine */}
      <div className="w-screen px-5 mb-3 bg-[#18181d] ggsmd">
        {selfRank?.id && (
          <RankingItem item={selfRank as TypeRankList}></RankingItem>
        )}
      </div>
      {/* {userinfo.isDailyFristLogin && <DailyPopup onClose={handleClose} />} */}
      {/* {true && <DailyPopup onClose={handleClose} />} */}
      {ntShow && <NumberAnimation number={award} duration={1000} />}
      {isSettingShow && (
        <SettingPopup
          isOpen={isSettingShow}
          onClose={() => setIsSettingShow(false)}
        ></SettingPopup>
      )}
      <InfiniteScroll
        loadMore={() => loadMore(page, current)}
        hasMore={hasMore}
      >
        <InfiniteScrollContent hasMore={hasMore} isShow={!RankList.length} />
      </InfiniteScroll>
      {/* <SafeArea position="bottom" /> */}
    </div>
  );
}
