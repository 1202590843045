import TakeHandPhoto from '@/components/TakeHandPhoto/TakeHandPhoto'
// import { useBackButton } from '@telegram-apps/sdk-react'
// import { useNavigateTo } from '@/hooks/useNavigate'
// import { useBaseDataStore } from '@/store/baseData'
// import { useEffect } from 'react'

export default function TakePhoto() {
  // const userinfo = useBaseDataStore((state) => state.userinfo)
  // const updateUserinfoKey = useBaseDataStore((state) => state.updateUserinfoKey)
  // const navigateToRank = useNavigateTo('/ranking')
  // const bb = useBackButton()
  // bb.on('click', () => {
  //   bb.hide()
  //   updateUserinfoKey({
  //     isBalanceJump: false,
  //   })
  //   navigateToRank()
  // })
  // useEffect(() => {
  //   if (userinfo.isBalanceJump) {
  //     bb.show()
  //   } else {
  //     bb.hide()
  //   }
  // }, [bb, userinfo.isBalanceJump])
  return (
    <div>
      <TakeHandPhoto></TakeHandPhoto>
    </div>
  )
}
