import React from 'react'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  children: React.ReactNode
  isShowCloseIcon?: boolean
  isMaskClick?: boolean
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  isShowCloseIcon = true,
  isMaskClick = true,
}) => {
  if (!isOpen) return null

  const handleOverlayClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (e.target === e.currentTarget) {
      if (isMaskClick) {
        onClose()
      }
    }
  }

  return (
    <div
      className="fixed inset-0 flex justify-center items-center mask z-10"
      onClick={handleOverlayClick}
    >
      <div className="relative z-10">
        {children}
        {isShowCloseIcon && (
          <div
            className="flex justify-center w-[40px] h-[40px] absolute top-[-55px] right-[-10px] text-gray-500 hover:text-gray-800"
            onClick={onClose}
          >
            <img className="w-[20px] h-[21px]" src="/imgs/btn_off.png" alt="" />
          </div>
        )}
      </div>
    </div>
  )
}

export default Modal
