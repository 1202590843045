import { useEffect, useMemo, useState } from 'react'
import ButtonZg from '@/components/ButtonZg/Buttonzg'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useNavigateTo } from '@/hooks/useNavigate'
import { useBackButton } from '@telegram-apps/sdk-react'
import { useUserDataStore } from '@/store/user'
const barList = [1, 2, 3]
export default function BeginGuide() {
  console.log(4398)

  const [current, setCurrent] = useState(1)
  const { t } = useTranslation()
  const userinfo = useUserDataStore((state) => state.userinfo)
  const updateUserinfoKey = useUserDataStore((state) => state.updateUserinfoKey)
  const navigateTo = useNavigateTo('/take-photo')
  const navigateToRank = useNavigateTo('/ranking')
  const bb = useBackButton()
  bb.on('click', () => {
    bb.hide()
    navigateToRank()
  })
  const handleCoutinueClick = () => {
    if (current < 3) {
      setCurrent(current + 1)
    } else {
      updateUserinfoKey({
        isBalanceJump: false,
      })
      navigateTo()
    }
  }
  useEffect(() => {
    if (userinfo.isBalanceJump) {
      bb.show()
    } else {
      bb.hide()
    }
  }, [bb, userinfo.isBalanceJump])
  const currentbanner = useMemo(() => {
    return [
      {},
      {
        src: '/imgs/page1.png',
        className: 'w-[292px] h-[292px]',
        des: [
          'beginGuideFirstDes_1',
          "beginGuideFirstDes_2",
          'beginGuideFirstDes_strong',
          // 'beginGuideFirstDes_2',
        ],
      },
      {
        src: '/imgs/page2.png',
        className: 'w-[252px] h-[313px]',
        des: [
          'beginGuideFirstDes_3',
          'beginGuideFirstDes_4',
          'beginGuideFirstDes_strong_2',
        ],
      },
      {
        src: '/imgs/page3.png',
        className: 'w-[280px] h-[280px]',
        des: ['beginGuideFirstDes_strong_3', 'beginGuideFirstDes_6'],
      },
    ][current]
  }, [current])

  return (
    <div className="flex flex-col align-middle">
      {/* progress bar */}
      <div className="flex mt-4">
        {barList.map((item) => {
          const isActive = current >= item
          return (
            <div
              className={`mr-2 w-[90px] h-[5px] rounded-[3px] ${
                isActive ? 'bg-thc' : 'bg-[#25252D]'
              }`}
              key={item}
            ></div>
          )
        })}
      </div>
      {/* main content*/}
      <div className="mt-9 flex flex-col align-middle">
        <div className="text-thc text-[18px]">
          {t('beginGuideFirstTitle_' + current)}
        </div>
        <img
          className={classNames('mt-4', currentbanner.className)}
          src={currentbanner.src}
        />
        {/* des */}
        <ButtonZg className="m-2 w-[335px]" btnRate={0.24}>
          <div className="p-2">
            {currentbanner.des?.map((item) => {
              return (
                <div
                  className={classNames(
                    'text-[15px] text-thc text-center',
                    item.includes('strong') && 'font-bold text-[22px]'
                  )}
                  key={item}
                >
                  {t(item)}
                </div>
              )
            })}
          </div>
        </ButtonZg>
      </div>
      <div className="mt-[20px]" onClick={handleCoutinueClick}>
        <ButtonZg
          btnRate={0.24}
          className="w-[207px] h-[59px] text-[24px] text-thc"
        >
          {t('Continue')}
        </ButtonZg>
      </div>
    </div>
  )
}
