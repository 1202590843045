import CustomButton from "@/components/CusButton/CusButton";
import Countdown from "@/components/CountDown/CountDown";
import ButtonZg from "@/components/ButtonZg/Buttonzg";
import { useUserDataStore } from "@/store/user";
import { Toast } from "antd-mobile";
import { getInviteLink } from "@/utils";
import { getStorage, setStorage } from "@/utils/storage";
import GiftHintModal from "./GIftHintModal";
import Loading from "@/components/Loading/Loading";
import { useTgUserInfo } from "@/hooks/useTguserInfo";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { getMutualAidsInfo } from "@/api/record";
const getCount = () => {
  const nowDate = (+new Date() / 1000) >> 0;
  const duration = getStorage("ads_duration") as number;
  return duration && duration > nowDate ? duration - nowDate : 0;
};
export default function Reward() {
  const adAward = 1000;
  const shareAward = [200, 500];
  const userinfo = useUserDataStore((state) => state.userinfo);
  const updateUserinfoKey = useUserDataStore(
    (state) => state.updateUserinfoKey
  );
  const [isLoading, setIsLoading] = useState(false);
  const [hintShow, SetHintShow] = useState(false);
  const [durationCount, setDurationCount] = useState(0);
  const [receiveInfo, setReceiveInfo] = useState({
    sendGiftCount: 0,
    getGiftCount: 0,
    totalWallet: 0,
  });
  const user = useTgUserInfo();
  const { t } = useTranslation();
  const isWatched = useMemo(() => {
    return userinfo.isWatched;
  }, [userinfo]);
  const btncolorInfo = useMemo(() => {
    return isWatched
      ? {
          cornerColor: "#879ec8",
          shadowColor: "rgba(135, 158, 200, 0.2)",
        }
      : { cornerColor: "#01dcfc", shadowColor: "rgba(1, 220, 252, 0.2)" };
  }, [isWatched]);
  const countDownFinsh = () => {
    if (!durationCount) {
      return;
    }
    console.log("Countdown finished");
    updateUserinfoKey({
      isWatched: false,
    });
  };
  const handleWatchAd = () => {
    if (isWatched) return;
    setIsLoading(true);
    console.log("watch ad");
    // const string = generateRandomString(6) // 生成6位随机字符串
    // const time = parseInt(new Date().getTime() / 1000)
    let blockId = userinfo.blockId;
    if (!blockId) {
      blockId = "475";
    }
    const AdController = window.Adsgram.init({ blockId: blockId });
    // tests
    const date = Math.floor(+new Date() / 1000) + 5;
    setStorage("ads_duration", date);
    updateUserinfoKey({
      isWatched: true,
    });
    const duration = getCount();
    setDurationCount(duration);
    // test
    console.log(userinfo.isWatched, 1111);
    AdController.show()
      .then((result: unknown) => {
        setIsLoading(false);
        console.log("ad======", result);
      })
      .catch((result: unknown) => {
        setIsLoading(false);
        Toast.show({
          content: "Hello World, This is a long text",
          afterClose: () => {
            console.log("after");
          },
        });
        console.log("adcatch======", result);
      });
  };

  const handleMoreGift = () => {
    console.log("handleMoreGift");
    const content = `🎁 I’m sending everyone a gift! 👆 Click the link to claim your $PALM. \n
🚀 Only the first 10 people can collect it, so hurry and grab your share!`;
    getInviteLink(user.id, 2, content);
  };

  useEffect(() => {
    const fn = async () => {
      const res = await getMutualAidsInfo();
      setReceiveInfo({
        totalWallet: res.gold,
        getGiftCount: res.send,
        sendGiftCount: res.seceiver,
      });
    };
    fn();
  }, []);

  const handleViewHint = () => {
    SetHintShow(true);
  };
  return (
    <div className="w-full h-full px-5 flex flex-col text-[18px] text-thc">
      <img
        className="w-[142px] h-[92px] mt-2"
        src="/imgs/reward_1.png"
        alt=""
      />
      {/* <CustomButton
        onClick={handleWatchAd}
        className="w-[336px] h-[65px] mt-[39px]"
        {...btncolorInfo}
      >
        <div className="flex justify-between">
          <div className="flex">
            <img
              className="w-[28px] h-[32px]"
              src={`/imgs/reward_${isWatched ? 4 : 3}.png`}
              alt=""
            />
            <div
              className={classNames(
                isWatched && "text-thg",
                "ml-[18px] rtl:mr-[18px]"
              )}
            >
              {t("reward.watch_ads")}
            </div>
          </div>
          {isWatched && (
            <Countdown
              seconds={durationCount}
              className="text-thg"
              onFinish={countDownFinsh}
            />
          )}
          {!isWatched && isLoading && <Loading size={20}></Loading>}
          {!isWatched && !isLoading && (
            <div className="flex">
              <div>+{adAward}</div>
              <img
                className="w-[33px] h-[33px] ml-1 rtl:mr-1"
                src="/imgs/earn.png"
                alt=""
              />
            </div>
          )}
        </div>
      </CustomButton> */}
      <div className="flex w-screen pl-[20px]" onClick={handleViewHint}>
        <img className="w-[20px] h-[20px]" src="/imgs/reward_rule.png" alt="" />
        <div className="text-[18px] my-[25px] ml-[5px] rtl:mr-[5px] w-full underline">
          {t("reward.wg")}
        </div>
      </div>
      <CustomButton
        className="w-[336px] min-h-[149px]"
        onClick={handleMoreGift}
      >
        <div className="flex flex-col">
          <div className="flex justify-between w-full">
            <div className="flex">
              <img
                className="w-[28px] h-[28px]"
                src="/imgs/reward_2.png"
                alt=""
              />
              <div className="ml-[12px] rtl:mr-[12px] font-bold">
                {" "}
                {t("reward.mg")}
              </div>
            </div>
            <div className="flex">
              <div>
                {shareAward[0]}~{shareAward[1]}
              </div>
              <img
                className="w-[33px] h-[33px] ml-1 rtl:mr-1"
                src="/imgs/earn.png"
                alt=""
              />
            </div>
          </div>
          <div className="flex bg-[#010f22] mt-2 w-full min-h-[64px] rounded-md p-2 justify-between">
            <div className="text-thg text-[15px] text-start">
              <div>
                {t("reward.sg")}(
                <span className="text-thc">{receiveInfo.sendGiftCount}</span>
                /10)
              </div>
              <div>
                {t("reward.gg")}(
                <span className="text-thc">{receiveInfo.getGiftCount}</span>/5)
              </div>
            </div>
            <div className="flex">
              <div>+{receiveInfo.totalWallet}</div>
              <img
                className="w-[33px] h-[33px] ml-1 rtl:mr-1"
                src="/imgs/earn.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </CustomButton>
      <ButtonZg
        onClick={handleMoreGift}
        // btnRate={0.24}
        className="w-[258px] h-[52px] mt-4 font-bold text-[19px]"
        topValue="11%"
      >
        {t("Send Gift")}
      </ButtonZg>
      {hintShow && (
        <GiftHintModal
          isOpen={hintShow}
          onClose={() => SetHintShow(false)}
        ></GiftHintModal>
      )}
    </div>
  );
}
