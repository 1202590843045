import classNames from "classnames";
import CustomButton from "@/components/CusButton/CusButton";
import {
  getDailyTask,
  getCurrentTaskStatus,
  getReward,
  getOneTimeTask,
} from "@/api/task";
// import { useNavigateTo } from '@/hooks/useNavigate'
import { useTranslation } from "react-i18next";
import { getUserInfo } from "@/api/user";
import { useUserDataStore } from "@/store/user";
import ButtonZg from "@/components/ButtonZg/Buttonzg";
import { useEffect, useState } from "react";
import { useTgUserInfo } from "@/hooks/useTguserInfo";
import Loading from "@/components/Loading/Loading";
import { openTelegramLink, getInviteLink, openLink } from "@/utils";
import TonConnectService from "@/config/pay";
import { Toast } from "antd-mobile";
type TaskItem = {
  id: number;
  title: string;
  img: string;
  status: number;
  award: number;
  payload: Record<string, string>;
  type: number;
};

const btnDes = ["", "earn.Go", "earn.receive", "earn.finish", "earn.Check"];
const tonConnectService = TonConnectService.getInstance();
export default function Earn() {
  const { t } = useTranslation();
  const [dailyTasks, setDailyTasks] = useState<TaskItem[]>([]);
  const [ordinaryTasks, setOrdinaryTasks] = useState<TaskItem[]>([]);
  const getDailyTask_fn = async () => {
    const res = await getDailyTask();
    setDailyTasks(res);
  };
  const getOnlyTimeTask_fn = async () => {
    const res = await getOneTimeTask();
    setOrdinaryTasks(res);
  };
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     getDailyTask_fn();
  //     getOnlyTimeTask_fn();
  //   }, 5000);
  //   return () => clearInterval(intervalId);
  // }, []);
  // Remove this event listener.
  useEffect(() => {
    getDailyTask_fn();
    getOnlyTimeTask_fn();
  }, []);
  return (
    <div className="w-full h-full px-5 flex flex-col">
      <img className="w-[142px] h-[92px] mt-2" src="/imgs/earn_1.png" alt="" />
      <Task
        classname="mt-[10px]"
        tasks={dailyTasks}
        title={t("earn.Daily_task")}
        taskFn={getDailyTask_fn}
      ></Task>
      <Task
        classname="mt-[10px]"
        tasks={ordinaryTasks}
        title={t("earn.Tasks")}
        taskFn={getOnlyTimeTask_fn}
      ></Task>
    </div>
  );
}

const Task = ({
  tasks,
  title,
  classname,
  taskFn,
}: {
  tasks: TaskItem[];
  title: string;
  classname?: string;
  taskFn: () => void;
}) => {
  const { t } = useTranslation();
  const user = useTgUserInfo();
  const [req, setReq] = useState(false);
  const [currentId, setCurrentId] = useState(0);
  const updateUserinfoKey = useUserDataStore(
    (state) => state.updateUserinfoKey
  );
  const updateBaseinfo = useUserDataStore((state) => state.updateBaseinfo);
  const handleConnectWalletClick = async () => {
    const isConnected = await tonConnectService.isConnecting();
    if (!isConnected) {
      try {
        await tonConnectService.connect();
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    const fn = async () => {
      const isConnected = await tonConnectService.isConnecting();
      if (!isConnected) {
        const timer = setInterval(async () => {
          const isConnected = await tonConnectService.isConnecting();
          if (isConnected) {
            taskFn();
            clearInterval(timer);
          }
        }, 2000);
      }
    };
    fn();
  }, [taskFn]);
  const handleBtnclick = async (item: TaskItem) => {
    if (req || item.status === 3) {
      return;
    }
    setCurrentId(item.id);
    setReq(true);
    console.log(item, user, "user");
    let res;
    if (
      (item.type == 4 || item?.img?.includes("invite")) &&
      item.status === 1
    ) {
      console.log(4396);
      setReq(false);
      return getInviteLink(user.id);
    }
    console.log(item.img);
    if (item?.img?.includes("wallet") && item.status === 1) {
      console.log(888888888888);
      setReq(false);
      return handleConnectWalletClick();
    }
    if (item.payload) {
      res = await getCurrentTaskStatus(item.type, user.id, item.payload?.id);
    }
    if (res === "link") {
      item.status = 4;
    }
    if (item.status === 2 || res === "reward") {
      const result = await getReward(item.id);
      if (result.code === 0 && result.data[0].number) {
        Toast.show({
          content: "Receive success",
          afterClose: () => {
            console.log("after");
          },
        });
        taskFn();
        getUserInfo().then((res) => {
          if (res.code === 0) {
            const data = res?.data;
            const gold = +data?.gold;
            updateBaseinfo(data);
            updateUserinfoKey({
              point: gold,
            });
          }
        });
      }
    }
    if (res === "link") {
      const url = item.payload.jump_url;
      if (url.includes("t.me")) {
        openTelegramLink(item.payload.jump_url);
      } else {
        openLink(item.payload.jump_url);
      }
    }
    setReq(false);
  };

  const handleClickContent = (item: TaskItem) => {
    openTelegramLink(item.payload.jump_url);
  };

  useEffect(() => {
    tonConnectService.setOnWalletChange(() => {
      console.log("connect wallet go back");
      taskFn();
    });
  }, []);
  return (
    <div className={classNames("w-full", classname)}>
      <div className="font-bold text-[24px] text-start text-thc">{title}</div>
      <CustomButton>
        <div className="flex flex-col w-[297px] flex-unset">
          {tasks.map((item, index) => {
            return (
              <div
                key={index}
                className={classNames(
                  "flex border-b",
                  index === tasks.length - 1
                    ? "border-none"
                    : "border-[#304a71]",
                  index !== 0 && "pt-[5px]",
                  index !== tasks.length - 1 && "pb-[5px]"
                )}
              >
                <div className="flex" onClick={() => handleClickContent(item)}>
                  <img
                    className="w-[30px] h-[30px]"
                    src={item?.img || "/imgs/balance_2.png"}
                    alt=""
                  />
                  <div className="flex flex-col ml-[15px] rtl:mr-[15px] rtl:ml-[unset] flex-unset">
                    <div className="font-bold text-[15px] text-start text-thc w-[165px] truncate">
                      {item.title}
                    </div>
                    <div className="flex justify-start">
                      <div className="text-[15px] text-start text-thg">
                        +{item.award}
                      </div>
                      <img
                        className="w-[20px] h-[20px] ml-1 rtl:mr-1 rtl:ml-[unset]"
                        src="/imgs/earn.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-1 flex justify-end items-center">
                  <ButtonZg
                    className={classNames("h-[28px]")}
                    style={{
                      width: `${t(btnDes[item.status]).length * 3 + 60}px`,
                    }}
                    btnRate={0.24}
                    isLine={false}
                    onClick={() => handleBtnclick(item)}
                  >
                    {req && currentId === item.id ? (
                      <Loading size={15}></Loading>
                    ) : (
                      t(btnDes[item.status])
                    )}
                  </ButtonZg>
                </div>
              </div>
            );
          })}
        </div>
      </CustomButton>
    </div>
  );
};
