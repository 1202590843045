import CustomButton from "@/components/CusButton/CusButton";
import ButtonZg from "@/components/ButtonZg/Buttonzg";
import { useState } from "react";
import { getAvatar, getInviteLink, openTelegramLink, openLink } from "@/utils";
import { useTgUserInfo } from "@/hooks/useTguserInfo";
import InfiniteScrollContent from "@/components/InfiniteScrollContent/InfiniteScrollContent";
import { getInvtiteFriends, type RecordType } from "@/api/task";
import { InfiniteScroll, Toast } from "antd-mobile";
import { useTranslation } from "react-i18next";

export default function Invite() {
  const user = useTgUserInfo();
  const { t } = useTranslation();
  const [friendList, setFriendList] = useState<RecordType[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState({
    all_total: 0,
    gold: 0,
  });
  const baseText = `🎉Join PalmCode and discover your fate! 🎉
Scan your palm✋, climb the ranks🏆, and earn $TON rewards💰.`;
  let baseUrl = `https://t.me/twa_stage_bot/palm?startapp=friend${user.id}`;
  if (import.meta.env.MODE !== "development") {
    baseUrl = `https://t.me/twa_stage_bot/palm?startapp=friend${user.id}`;
  }
  const shareList = [
    {
      id: 1,
      img: "/imgs/share_link.png",
      url: "https://www.baidu.com",
    },
    {
      id: 2,
      img: "/imgs/share_whatsapp.png",
      url: `https://api.whatsapp.com/send?text=${encodeURIComponent(
        baseText
      )}${baseUrl}`,
    },
    {
      id: 3,
      img: "/imgs/share_x.png",
      url: `https://twitter.com/intent/tweet?url=${baseUrl}&text=${encodeURIComponent(
        baseText
      )}`,
    },
    {
      id: 4,
      img: "/imgs/share_facebook.png",
      url: `https://www.facebook.com/sharer/sharer.php?u=" + ${baseUrl} + '\n'`,
    },
  ];
  const handleShare = (item: (typeof shareList)[number]) => {
    console.log(item, 4397);
    console.log(user.id);
    if (item.id === 1) {
      const isDev =
        import.meta.env.VITE_MODEL === "development" ||
        import.meta.env.VITE_MODEL === "preview";
      let textToCopy = `https://t.me/twa_stage_bot/palm?startapp=friend${user.id}`;
      console.log(isDev, "isDev");

      if (isDev) {
        textToCopy = `https://t.me/twa_stage_bot/palm?startapp=friend${user.id}`;
      }
      navigator.clipboard.writeText(textToCopy);
      Toast.show({
        content: "Copied",
        afterClose: () => {
          console.log("after");
        },
      });
    } else {
      openLink(item.url);
    }
  };

  async function loadMore(page = 1) {
    console.log(page, "more->>>>>>");
    const append = await getInvtiteFriends({
      page,
    });
    console.log(append, "append");

    setFriendList((val) => {
      if (page === 1) {
        return append.list;
      }
      return [...val, ...append.list];
    });
    setHasMore(append.list.length > 0);
    setTotal(append.total);
    setPage((prevPage) => prevPage + 1);
  }

  const handleJumpTg = (item: (typeof friendList)[number], status = 1) => {
    if (status === 2) {
      navigator.clipboard.writeText(baseText);
    }
    openTelegramLink(`https://t.me/${item?.username}`);
  };
  const handleInviteFriend = () => {
    console.log("handleInviteFriend");
    getInviteLink(user.id);
  };

  return (
    <div className="flex flex-col w-full h-full p-[20px] text-thc">
      <div className="text-[24px] font-bold">
        {t("takePhoto_Invite.invite_title")}
      </div>
      <CustomButton className="w-full text-[18px] mt-[10px]">
        <div className="flex flex-col w-full">
          <div className="flex">
            <img
              className="w-[36px] h-[28px] rtl:w-[28px] mr-[13px] rtl:ml-[13px] rtl:mr-[unset]"
              src="/imgs/invite_1.png"
              alt=""
            />
            <div className="flex flex-col">
              <div className="font-bold text-start w-full">
                {t("takePhoto_Invite.invite_reward")}
              </div>
              <div className="text-start text-thg text-[16px]">
                <span className="font-bold text-thc">+20%</span>
                {t("takePhoto_Invite.invite_des")}
              </div>
            </div>
          </div>
          <div className="flex mt-[10px] justify-around">
            <img
              className="w-[36px] h-[28px] rtl:w-[28px] mr-[13px] rtl:ml-[13px] rtl:mr-[unset]"
              src="/imgs/reward_2.png"
              alt=""
            />
            <div className="flex flex-col">
              <div className="font-bold text-start w-full">
                {t("takePhoto_Invite.invite_bonus")}
              </div>
              <div className="text-start text-thg text-[16px]">
                <span>{t("takePhoto_Invite.invite_results")}</span>
                <span className="font-bold text-thc px-1">30%</span>
                <span>{t("takePhoto_Invite.invite_results1")}</span>
              </div>
            </div>
          </div>
          <div className="flex mt-[10px] w-full justify-center">
            <ButtonZg
              onClick={handleInviteFriend}
              // btnRate={0.24}
              className="w-[258px] h-[43px] font-bold text-[18px]"
              topValue="11%"
            >
              {t("takePhoto_Invite.invite_title")}
            </ButtonZg>
            {/* <ButtonZg className="w-[106px] h-[43px]" topValue="11%">
              <div>
                <img
                  className="w-[20px] h-[23px]"
                  src="/imgs/invite_1.png"
                  alt=""
                />
              </div>
            </ButtonZg> */}
          </div>
          <div className="flex mt-[13px] mb-[15px] w-full justify-center">
            <div className="text-[15px]">{t("takePhoto_Invite.share_to")}</div>
            <div className="flex ml-[15px] rtl:mr-[15px]">
              {shareList.map((item) => {
                return (
                  <img
                    className="w-[27px] h-[27px] mr-[10px] rtl:ml-[10px] rtl:mr-[unset]"
                    src={item.img}
                    alt=""
                    key={item.id}
                    onClick={() => handleShare(item)}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </CustomButton>
      <div className="flex justify-between w-full mt-[20px]">
        <div className="text-[18px] font-bold text-thg">
          Friend List(<span className="text-thc">{total.all_total}</span>)
        </div>
        <div className="font-bold text-thc text-[24px] flex">
          <span>+{total?.gold || 0}</span>
          <img
            className="w-[27px] h-[27px] ml-[10px] rtl:mr-[10px] rtl:ml-[unset]"
            src="/imgs/earn.png"
            alt=""
          />
        </div>
      </div>
      <div className="flex flex-col justify-between w-full">
        {friendList.map((item, index) => {
          return (
            <div
              key={index}
              className="flex text-thc justify-between w-full mt-[20px]"
            >
              <div className="flex">
                <img
                  className="w-[46px] h-[46px] rounded-[7px] basic-img"
                  src={getAvatar(item.tgid)}
                  alt=""
                  onClick={() => handleJumpTg(item)}
                />
                <div className="ml-[20px] rtl:mr-[20px] rtl:ml-[unset] truncate w-[140px] text-[18px] font-bold">
                  {item.name}
                </div>
              </div>
              {item.gold ? (
                <div className="font-bold text-thc text-[18px] flex">
                  <span>+{item.gold}</span>
                  <img
                    className="w-[27px] h-[27px] ml-[10px] rtl:mr-[10px] rtl:ml-[unset]"
                    src="/imgs/earn.png"
                    alt=""
                  />
                </div>
              ) : (
                <ButtonZg
                  onClick={() => handleJumpTg(item, 2)}
                  btnRate={0.24}
                  className="w-[88px] h-[43px] text-thc"
                >
                  {t("takePhoto_Invite.remind")}
                </ButtonZg>
              )}
            </div>
          );
        })}
      </div>
      <InfiniteScroll loadMore={() => loadMore(page)} hasMore={hasMore}>
        <InfiniteScrollContent
          hasMore={hasMore}
          text={"No friends have registered yet"}
          isShow={!friendList.length}
        />
      </InfiniteScroll>
    </div>
  );
}
